import * as React from "react"
import { Link, graphql } from "gatsby"
import { StaticImage} from "gatsby-plugin-image"

import Layout from "../components/layout"
import Seo from "../components/seo"

const Index = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata?.title || `Title`

  return (
    <Layout className="homepage-container" location={location} title={siteTitle}>
      <section className="landing-hero">
        <h1 className="landing-header">Are you ready to evolve your energy?</h1>
        <StaticImage
            className="landing-image"
            layout="constrained"
            formats={["auto", "webp", "avif"]}
            src="../images/vision-board-oval.png"
            height={400}
            quality={95}
            alt="vision board"
          />
        <p className="landing-invite">Do better than napping to feel revitalized...</p>
        <Link to="/services" className="landing-button">Learn More</Link>
      </section>
      <section className="wellness-introduction">
        <p><b>At Zai Duck, we believe that everyone should feel empowered to revitalize their personal wellness.</b> Life is too short to be spent on suffering from stress and anxiety. <b>Our approach is to equip with mindful tools that will transform our daily habits and rejuvenate our energy.</b></p>
      </section>
      <section className="featured-program">
        <div className="featured-program-tab">
          <h4>Featured Program</h4>
        </div>
        <div className="featured-program-body">
          <StaticImage
            className="featured-program-image"
            layout="fixed"
            formats={["auto", "webp", "avif"]}
            src="../images/full-meditation-squiggle.png"
            width={300}
            height={300}
            quality={95}
            alt="reiki meditation"
            />
            <div className="featured-program-description">
              <h4 className="featured-program-title">Energy Wellness Program</h4>
              <h5 className="featured-program-subtitle">Body and Mind Connection</h5>
              <p><em>What experiences would you live if you evolved your energy?</em></p> 
              <p>This program focuses on your body and mind connection in order to transform your energy. Boost energy with meditation and reiki healing, sustain wellness by evaluating personal values and habits, and maintain balance throughout challenges with a shifted mindset. Throughout the program you will incorporate, reflect on, and practice techniques to improve sleep quality and mental clarity, feel rejuvenated and lighter, and gain a greater sense of well-being and inner peace.</p>
              <button className="landing-button"><a href="https://zai-duck-llc.square.site" target="_blank" rel="noopener noreferrer">Sign up</a></button>
          </div>
        </div>
      </section>
      <div className="session-button-container">
        <StaticImage
          className="landing-session-image"
          layout="constrained"
          formats={["auto", "webp", "avif"]}
          src="../images/large-squiggle-button.png"
          width={375}
          quality={95}
          alt="session list directory"
        />
        <Link className="landing-session-button" to="/services">View our full session list</Link>
      </div>
    </Layout>
  )
}

export default Index

/**
 * Head export to define metadata for the page
 *
 * See: https://www.gatsbyjs.com/docs/reference/built-in-components/gatsby-head/
 */
export const Head = () => <Seo title="Zai Duck LLC" />

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
  `
